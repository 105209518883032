import * as React from 'react'
import { useState } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/layout/Layout'
import Categories from '../templates/categories'
import { Image } from '../components/image/Image'
import { header, container, h1, post, title, grid, image, excerpt } from '../styles/blog.module.css'

export default function Home() {

  const data = useStaticQuery(graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, filter: { frontmatter: { type: { eq: "blog"}  } }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            category
            type
            title
            date(formatString: "DD MMMM, YYYY")
            thumbnail {
              publicURL
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`)

  const [category, setCategory] = useState('');

  const showSelectedCategory = (category: any) => {
    setCategory(category)
  }

  const filtered = data.allMarkdownRemark.edges.filter((post)=> post.node.frontmatter.category.includes(category))

  return (
    <Layout>
      <header className={header}>
        <div className={container}>
          <h1 className={h1}>Blog</h1>
        </div>
      </header>
      <div className={ `${container} ${grid}` }>
        <main>
          {(category ? filtered : data.allMarkdownRemark.edges).map(({ node }) => (
            <article className={post} key={node.id}>
              <Image className={image} url={node.frontmatter?.thumbnail?.publicURL} />
              <div>
              <h3 className={title}><Link to={node.fields.slug}>{node.frontmatter.title}{' '}</Link></h3>
                <span>{node.frontmatter.date}</span>
                <p className={excerpt} dangerouslySetInnerHTML={{__html: node.excerpt}} />                
              </div>
            </article>
          ))}
        </main>
        <aside>
          <Categories 
            showCategory={showSelectedCategory}
            selectedCategory={category}
          />
        </aside>
      </div>
    </Layout>
  )
}